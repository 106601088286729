body, html {
  font-size: 16px;
  padding: 0;
  margin: 0;
  line-height: 1.5;
  background: #dee5ea;
  font-family: 'Montserrat Alternates', sans-serif;
  font-weight: 300;
}

section {
  padding: 1em;
  text-align: center;
  max-width: 600px;
  margin: auto;
}

footer {
  padding: 1em;
  text-align: center;
  font-size: 14px;
  color: white;
  background: #333;
  position: relative;

  &::before {
    position: absolute;
    content: '';
    display: block;
    height: 10px;
    right: 0px;
    background: #1ca798;
    opacity: 0.3;
    top: -10px;
    left: 0px;
  }
}

h1 {
  margin-top: 1em;
  margin-bottom: 1em;
  font-size: 38px;
  font-weight: 300;
}

h2 {
  margin-top: 1em;
  margin-bottom: 1em;
  font-size: 28px;
  font-weight: 300;
}

li {
  margin-bottom: 0.5em;
  text-align: left;
}

p {
  margin: 2em 0 2em 0;
}

a {
  text-decoration: none;
  border-bottom: 2px dotted #aaa;
  color: #1ca798;
  font-weight: 500;
  &:hover {
    color: #b013ba;
  }
}

hr {
  background-image: url(/images/separator.svg);
  background-size: contain;
  height: 60px;
  border: none;
  background-repeat: no-repeat;
  background-position: center;
  opacity: 0.2;
  margin-top: 60px;
  margin-bottom: 60px;
}

.team-members {
  display: flex;
  width: 100%;
  margin-bottom: 2em;
  justify-content: center;
}

.team {
  margin: 0 2em;
  & p {
    margin: 0;
    padding: 0;
  }
  & .name {
    font-size: 1.25em;
    font-weight: 500;
    margin-bottom: 0.5em;
    margin-top: 0.25em;
  }
  & .portrait {
    overflow: hidden;
    border-radius: 75px;
    width: 150px;
    height: 150px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);

    & img {
      width: 100%;
      height: 100%;
    }
  }
}

#splash {
  margin-top: 1em;
  height: 150px;
  max-width: 800px;
  margin-bottom: -3em;
  margin-left: auto;
  margin-right: auto;
  & svg {
    height: 150px;
    max-width: 100%;
    filter: drop-shadow(-2px -2px 5px rgba(10, 10, 10, 0.2));
  }
  & .letter.pulse {
    animation: 10s letter-pulse infinite both;
    animation-timing-function: linear;
  }
}

@keyframes letter-pulse {
  0% {
    fill: hsl(292, 40%, 20%);
    stroke-width: 4px;
    stroke: hsl(292, 70%, 80%);
  }

  25% {
    fill: hsl(195, 40%, 20%);
    stroke: hsl(195, 70%, 80%);
  }

  50% {
    fill: hsl(119, 40%, 20%);
    stroke: hsl(119, 70%, 80%);
  }

  75% {
    fill: hsl(56, 40%, 20%);
    stroke: hsl(56, 70%, 80%);
  }

  100% {
    fill: hsl(292, 40%, 20%);
    stroke-width: 4px;
    stroke: hsl(292, 70%, 80%);
  }

}
